import React from "react";
import { PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { Container, Jumbotron, Row, Col, Button } from "react-bootstrap";
import style from './terms-and-conditions.module.scss';
import { useTranslation } from "react-i18next";

export default function TermsAndConditions(props: PageProps) {

    const { t } = useTranslation();

    return (
        <Layout pageProps={props}>
            {/* Will be changed and translated with real data */}
            <Container className={style.termsContainer}>
                <h2 className={style.title}>{t("Terms")}</h2>
                <h2 className={style.headline}>Headline</h2>
                <p className={style.termsParagraph}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </p>

                {/* HARDCODED FOR DESIGN TEST */}
                <h2 className={style.headline}>Headline</h2>
                <p className={style.termsParagraph}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </p>

                {/* HARDCODED FOR DESIGN TEST */}
                <h2 className={style.headline}>Headline</h2>
                <p className={style.termsParagraph}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </p>

                {/* HARDCODED FOR DESIGN TEST */}
                <h2 className={style.headline}>Headline</h2>
                <p className={style.termsParagraph}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </p>

                {/* HARDCODED FOR DESIGN TEST */}
                <h2 className={style.headline}>Headline</h2>
                <p className={style.termsParagraph}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                </p>
            </Container>
        </Layout>
    );
}